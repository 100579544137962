import { RewardCalculatorPortalFlow } from '/features/buildingBlocks/reward-calculator/RewardCalculatorPortalFlow'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { routeMap } from '/routeMap'

import styles from './ShowRewardCalculatorPortalFlowButton.css'

/** @param {ShowRewardCalculatorPortalFlowButtonProps} props */
export function ShowRewardCalculatorPortalFlowButton({
  buttonType,
  dataX,
  label,
  linkRef,
  initialScale = undefined,
  layoutClassName = undefined
}) {
  const [isCalculatorOpen, setIsCalculatorOpen] = React.useState(false)
  const ButtonComponent = getButtonComponent({ buttonType })
  const language = useLanguage()
  const { __ } = useTranslate()

  const link = linkRef && {
    url: routeMap.app.page({ language, slug: linkRef.slug.current }),
    label: __`see-the-benefits`
  }

  return (
    <>
      <ButtonComponent {...{ dataX, label, layoutClassName }} onClick={() => setIsCalculatorOpen(true)} />
      <RewardCalculatorPortalFlow
        isOpen={isCalculatorOpen}
        onChange={setIsCalculatorOpen}
        {...{ link, initialScale }}
      />
    </>
  )
}

function getButtonComponent({ buttonType }) {
  switch (buttonType) {
    case 'label': return Label
    case 'tile': return Tile
    default: throw new Error('Invalid button type passed in ShowRewardCalculatorPortalFlowButton component. Use one of the following: \'label\', \'tile\'')
  }
}

function Label({ label, dataX, onClick }) {
  return (
    <button type="button" data-x={dataX} className={styles.componentLabel} {...{ onClick }}>
      <span>{label}</span>
    </button>
  )
}

function Tile({ label, dataX, onClick, layoutClassName }) {
  return (
    <button type="button" data-x={dataX} className={cx(styles.componentTile, layoutClassName)} {...{ onClick }}>
      <span>{label}</span>
    </button>
  )
}

/**
 * @typedef {Object} ShowRewardCalculatorPortalFlowButtonProps
 * @property {String} label
 * @property {'label' | 'tile'} buttonType
 * @property {String} dataX
 * @property {Link} linkRef
 * @property {String} [layoutClassName]
 * @property {Number} [initialScale]
 */

/**
 * @typedef {Object} Link
 * @property {React.MutableRefObject<String>} [slug]
 */
